const PAGE_IS_SCROLLED = 80;

const wrapperWithTransparentHeader = document.querySelector('.wrapper--transparent-header');

const initHeaderColorChange = () => {
  const logo = document.querySelector('.header__logo-img');

  if (logo) {
    logo.dataset.logoLightSrc = logo.src;
  }

  if (!wrapperWithTransparentHeader) {
    return;
  }

  const firstBlock = wrapperWithTransparentHeader.querySelectorAll('[data-transparent-header]')[0];

  if (!firstBlock) {
    return;
  }

  const header = wrapperWithTransparentHeader.querySelector('.header');

  const onWindowScroll = () => {
    if (window.pageYOffset >= PAGE_IS_SCROLLED) {
      header.classList.add('is-scrolled');
      logo.src = logo.dataset.logoColorSrc;
    } else {
      header.classList.remove('is-scrolled');
      logo.src = logo.dataset.logoLightSrc;
    }
  };

  onWindowScroll();

  window.addEventListener('resize', onWindowScroll);
  window.addEventListener('scroll', onWindowScroll);
};

export {initHeaderColorChange};
