const prevalidateForms = document.querySelectorAll('[data-prevalidate]');

const initFormPrevalidate = () => {
  if (!prevalidateForms.length) {
    return;
  }

  prevalidateForms.forEach((form) => {
    const inputs = form.querySelectorAll('input[aria-required="true"]');
    const selects = form.querySelectorAll('select[aria-required="true"]');
    const submitBtn = form.querySelector('button[type="submit"]');

    submitBtn.disabled = true;

    let isAllInputsHasValues = !inputs.length;
    let isAllSelectsHasValues = !selects.length;

    const prevalidateMainAction = () => {
      if (isAllInputsHasValues && isAllSelectsHasValues) {
        submitBtn.disabled = false;

        form.removeEventListener('input', onFormInput);

        selects.forEach((select) => {
          select.removeEventListener('change', onFormSelectChange);
        });
      }
    };

    const onFormInput = () => {
      isAllInputsHasValues = Array.from(inputs).every((input) =>
        input.value && input.value !== 'on' || input.checked);

      prevalidateMainAction();
    };

    const onFormSelectChange = () => {
      setTimeout(() => {
        isAllSelectsHasValues = Array.from(selects).every((select) => !!select.value);

        prevalidateMainAction();
      });
    };

    form.addEventListener('input', onFormInput);

    selects.forEach((select) => {
      select.addEventListener('change', onFormSelectChange);
    });
  });
};

export {initFormPrevalidate};
