const initSliderTabs = () => {
  const sliders = document.querySelectorAll('.slider-tabs');

  if (!sliders.length) {
    return;
  }

  sliders.forEach((slider) => {
    const container = slider.querySelector('.slider-tabs__container');

    // eslint-disable-next-line
    const swiper = new Swiper(container, {
      slidesPerView: 'auto',
      spaceBetween: 16,
      grabCursor: true,
      mousewheel: {
        forceToAxis: true,
      },
    });
  });
};

export {initSliderTabs};
