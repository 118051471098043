const Gap = {
  MOBILE: 24,
  DESKTOP: 24,
};

const breakpoint599 = window.matchMedia('(max-width: 599px)');
const breakpoint767 = window.matchMedia('(max-width: 767px)');
const breakpoint1023 = window.matchMedia('(max-width: 1023px)');

const calculateSlidesGap = () => document.documentElement.clientWidth > 767 ? Gap.DESKTOP : Gap.MOBILE;

const calculateSlidesPerView = () => {
  if (document.documentElement.clientWidth > 1023) {
    return 4;
  } else if (document.documentElement.clientWidth > 767) {
    return 3;
  } else if (document.documentElement.clientWidth > 599) {
    return 2;
  } else {
    return 1;
  }
};

const initSliderApplications = () => {
  const sliders = document.querySelectorAll('.slider-applications');

  if (!sliders.length) {
    return;
  }

  sliders.forEach((slider) => {
    const container = slider.querySelector('.slider-applications__container');
    const slides = container.querySelectorAll('.slider-applications__slide');
    const controls = slider.querySelector('.slider-applications__controls');
    const navBtns = controls.querySelector('.slider-applications__nav-btns');
    const prevBtn = navBtns.querySelector('.nav-btn--prev');
    const nextBtn = navBtns.querySelector('.nav-btn--next');
    const paginationElem = controls.querySelector('.slider-applications__pagination');
    const navigation = {
      prevEl: prevBtn,
      nextEl: nextBtn,
    };
    const pagination = {
      el: paginationElem,
      bulletClass: 'slider-applications__pagination-bullet',
      bulletActiveClass: 'slider-applications__pagination-bullet--active',
    };

    const checkIfNeedNavigation = () => {
      const gap = calculateSlidesGap();
      const slideWidth = slides[0].offsetWidth;
      const slidesLength = slides.length * (slideWidth + gap) - gap;

      return slidesLength > slider.offsetWidth + 2;
    };

    const calculateNavBtnsTop = () =>
      Math.round(slides[0].querySelector('.material-card__cover-img').offsetHeight / 2);

    let swiper;
    let slidesPerView = calculateSlidesPerView();
    let isNeedNavigation = checkIfNeedNavigation();

    const initSwiper = () => {
      // eslint-disable-next-line no-undef
      swiper = new Swiper(container, {
        slidesPerView: 'auto',
        spaceBetween: Gap.MOBILE,
        grabCursor: true,
        pagination: isNeedNavigation ? pagination : false,
        mousewheel: {
          forceToAxis: true,
        },
        breakpoints: {
          600: {
            slidesPerView,
            pagination: isNeedNavigation ? pagination : false,
          },
          768: {
            slidesPerView,
            spaceBetween: Gap.DESKTOP,
            pagination: false,
            navigation: slides.length > slidesPerView ? navigation : false,
          },
        },
      });

      if (slides.length > slidesPerView || isNeedNavigation) {
        slider.classList.remove('slider-applications--low-content');
        navBtns.style.display = null;
      } else {
        slider.classList.add('slider-applications--low-content');
        navBtns.style.display = 'none';
      }

      if (isNeedNavigation) {
        paginationElem.style.display = null;
      } else {
        paginationElem.style.display = 'none';
      }
    };

    const reInitSwiper = () => {
      slidesPerView = calculateSlidesPerView();

      if (swiper) {
        swiper.destroy(true, true);
      }

      initSwiper();
    };

    const onWindowResize = () => {
      const checkResult = checkIfNeedNavigation();
      const top = calculateNavBtnsTop();

      if (isNeedNavigation !== checkResult) {
        isNeedNavigation = checkResult;
        reInitSwiper();
      }

      prevBtn.style.top = `${top}px`;
      nextBtn.style.top = `${top}px`;
    };

    initSwiper();
    onWindowResize();

    breakpoint599.addListener(reInitSwiper);
    breakpoint767.addListener(reInitSwiper);
    breakpoint1023.addListener(reInitSwiper);

    window.addEventListener('resize', onWindowResize);
  });
};

export {initSliderApplications};
