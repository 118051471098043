import {iosVhFix} from './utils/ios-vh-fix';
import {initMenu} from './modules/init-menu';
import tippy from 'tippy.js';
window.tippy = tippy;

import {initSliderPhotos} from './modules/sliders/slider-photos';
import {initSliderProducts} from './modules/sliders/slider-products';
import {initSliderProductCard} from './modules/sliders/slider-product-card';
import {initSliderMaterials} from './modules/sliders/slider-materials';
import {initSliderTabs} from './modules/sliders/slider-tabs';
import {initSliderDealers} from './modules/sliders/slider-dealers';
import {initSliderApplications} from './modules/sliders/slider-applications';

import {initTableOfContent} from './modules/init-table-of-content';

import {initModals} from './modules/modals/init-modals';
import {initCustomSelect} from './modules/form/init-custom-select';
import {initFormValidate} from './modules/form/init-form-validate';
import {initLabelMoving} from './modules/form/init-label-moving';
import {initFormValidateBehavior} from './modules/form/init-form-validate-behavior';
import {initFormPrevalidate} from './modules/form/init-form-prevalidate';
import {initDepInputs} from './modules/form/init-dep-inputs';
import {initAutoResizeTextarea} from './modules/form/auto-resize-textarea';
import {initTabs} from './modules/init-tabs';
import {initTabsSpecifications} from './modules/tabs-specifications';
import {initTooltip} from './modules/init-tooltips';
import {initCalculatorModalCheck} from './modules/init-calculator-modal-check';

// ---------------------------------

window.addEventListener('DOMContentLoaded', () => {

  // Utils
  // ---------------------------------

  iosVhFix();
  initMenu();

  initSliderPhotos();
  initSliderProducts();
  initSliderProductCard();
  initSliderMaterials();
  initSliderApplications();
  initSliderTabs();
  initSliderDealers();

  initTableOfContent();

  initCalculatorModalCheck();

  // Modules
  // ---------------------------------

  // все скрипты должны быть в обработчике 'DOMContentLoaded', но не все в 'load'
  // в load следует добавить скрипты, не участвующие в работе первого экрана
  window.addEventListener('load', () => {
    // Sliders

    initModals();
    initCustomSelect();
    initFormValidate();
    initLabelMoving();
    initFormValidateBehavior();
    initFormPrevalidate();
    initDepInputs();
    initAutoResizeTextarea();
    initTabs();
    initTabsSpecifications();
    initTooltip();
  });
});

// ---------------------------------

// ❗❗❗ обязательно установите плагины eslint, stylelint, editorconfig в редактор кода.

// привязывайте js не на классы, а на дата атрибуты (data-validate)

// вместо модификаторов .block--active используем утилитарные классы
// .is-active || .is-open || .is-invalid и прочие (обязателен нейминг в два слова)
// .select.select--opened ❌ ---> [data-select].is-open ✅

// выносим все в дата атрибуты
// url до иконок пинов карты, настройки автопрокрутки слайдера, url к json и т.д.

// для адаптивного JS используейтся matchMedia и addListener
// const breakpoint = window.matchMedia(`(min-width:1024px)`);
// const breakpointChecker = () => {
//   if (breakpoint.matches) {
//   } else {
//   }
// };
// breakpoint.addListener(breakpointChecker);
// breakpointChecker();

// используйте .closest(el)
