const initCalculatorModalCheck = () => {
  const modalShown = localStorage.getItem('calculationModal');

  if (modalShown === null || modalShown === false) {
    return false;
  }

  const hiddenClass = 'is-hidden';

  let calculator        = document.querySelector('[data-component="calculator"]');
  let modalButtons      = calculator.querySelectorAll('[data-open-modal="contact-form"]');
  let calculatorButtons = calculator.querySelectorAll('[data-element="calculator-submit-button"]');

  modalButtons.forEach((button) => {
    button.classList.add(hiddenClass);
  });

  calculatorButtons.forEach((button) => {
    button.classList.remove(hiddenClass);
  });
};

export {initCalculatorModalCheck};
