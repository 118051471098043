import {initHeaderColorChange} from './init-header-color-change';

const breakpoint = window.matchMedia('(min-width: 1240px)');

const wrapperWithTransparentHeader = document.querySelector('.wrapper--transparent-header');
const header = document.querySelector('.header');

const initMenu = () => {
  initHeaderColorChange();

  if (!header) {
    return;
  }

  const mainNav = header.querySelector('.main-nav');
  const menuToggle = header.querySelector('.header__menu-toggle');

  if (!mainNav || !menuToggle) {
    return;
  }

  const logo = header.querySelector('.header__logo-img');
  const mainNavWrapper = mainNav.querySelector('.main-nav__wrapper');
  const mainNavInner = mainNavWrapper.querySelector('.main-nav__inner');
  const mainNavcontent = mainNavInner.querySelector('.main-nav__content');
  const overlay = document.querySelector('.main-nav__overlay');

  let logoSrcBuffer;

  const toggleMenuTransition = (isOn = true) => {
    mainNavWrapper.classList.toggle('no-transition', isOn);
    mainNavInner.classList.toggle('no-transition', isOn);
  };

  const onEscPress = (evt) => {
    if (evt.key === 'Escape' || evt.key === 'Esc') {
      evt.preventDefault();

      closeMenu();
    }
  };

  const onOverlayClick = () => {
    closeMenu();
  };

  const openMenu = () => {
    if (wrapperWithTransparentHeader) {
      logoSrcBuffer = logo.src;

      setTimeout(() => {
        logo.src = logo.dataset.logoColorSrc;
      }, 300);
    }


    header.classList.add('menu-is-open');
    mainNav.classList.add('is-open');
    menuToggle.classList.add('is-open');
    menuToggle.ariaPressed = 'true';

    window.focusLock.lock('.main-nav', false);
    window.scrollLock.disableScrolling();

    document.addEventListener('keydown', onEscPress);
    overlay.addEventListener('click', onOverlayClick);
  };

  const closeMenu = () => {
    if (wrapperWithTransparentHeader) {
      setTimeout(() => {
        logo.src = logoSrcBuffer;
      }, 300);
    }

    header.classList.remove('menu-is-open');
    mainNav.classList.remove('is-open');
    menuToggle.classList.remove('is-open');
    menuToggle.ariaPressed = 'false';

    window.focusLock.unlock(false);
    window.scrollLock.enableScrolling();

    document.removeEventListener('keydown', onEscPress);
    overlay.removeEventListener('click', onOverlayClick);
  };

  const onMenuToggleClick = () => {
    if (mainNav.classList.contains('is-open')) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  const onMenuElemClick = ({target}) => {
    if (mainNav.classList.contains('is-open')
          && (target.closest('.main-nav__link') || target.closest('[data-open-modal]'))) {
      closeMenu();
    }
  };

  const breakpointChecker = () => {
    if (breakpoint.matches && mainNav.classList.contains('is-open')) {
      closeMenu();
    }

    toggleMenuTransition(true);
    setTimeout(toggleMenuTransition, 600, false);
  };

  if (!logo.dataset.logoLightSrc) {
    logo.dataset.logoLightSrc = logo.src;
  }

  breakpointChecker();
  breakpoint.addListener(breakpointChecker);

  menuToggle.addEventListener('click', onMenuToggleClick);
  mainNavcontent.addEventListener('click', onMenuElemClick);
};

export {initMenu};
