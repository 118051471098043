import {tabs} from './init-tabs';

const breakpoint599 = window.matchMedia('(max-width: 599px)');

const initTabsSpecifications = () => {
  const tabBlocks = document.querySelectorAll('.specifications');

  if (!tabBlocks.length) {
    return;
  }

  tabBlocks.forEach((tabBlock) => {
    const tabElements = tabBlock.querySelectorAll('[data-tabs]');
    const tabContent = tabBlock.querySelector('.specifications__categories');

    tabElements.forEach((tabElement) => {
      tabElement.dataset.tabsClone = tabElement.dataset.tabs;
    });

    const initBlock = () => {
      if (breakpoint599.matches) {
        tabElements.forEach((tabElement) => {
          tabElement.style.transition = 'none';

          tabElement.dataset.tabs = tabElement.dataset.tabsClone;

          setTimeout(() => {
            tabElement.style.transition = null;
          });
        });

        tabs.reInit();
      } else {
        tabElements.forEach((tabElement) => {
          tabElement.style.transition = 'none';

          tabElement.removeAttribute('data-tabs');
          tabContent.style.height = null;

          setTimeout(() => {
            tabElement.style.transition = null;
          });
        });
      }
    };

    initBlock();

    breakpoint599.addListener(initBlock);
  });
};

export {initTabsSpecifications};
