const inputs = document.querySelectorAll('.custom-input, .custom-toggle');

const setActualInputError = (input) => {
  const error = input.querySelector('.custom-input__error');

  if (error) {
    const realHTMLInput = input.querySelector('input');
    if ((realHTMLInput.value && realHTMLInput.value !== 'on' || realHTMLInput.checked) && input.dataset.inputError) {
      error.textContent = input.dataset.inputError;
    } else if (input.dataset.inputEmptyError) {
      error.textContent = input.dataset.inputEmptyError;
    }
  }
};

const initFormValidateBehavior = () => {
  if (!inputs.length) {
    return;
  }

  inputs.forEach((input) => {
    const error = input.querySelector('.custom-input__error');

    if (error) {
      input.dataset.inputError = error.textContent;

      setActualInputError(input);
    }
  });

  inputs.forEach((input) => {
    input.addEventListener('change', () => {
      setActualInputError(input);
    });
  });
};

export {initFormValidateBehavior};
