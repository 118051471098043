import tippy from 'tippy.js';

const initTooltip = () => {
  const tippyAnchors = document.querySelectorAll('[data-tippy-anchor]');

  const tippyInstances = tippy('[data-tippy-content]', {
    theme: 'light',
    content: 'Add tooltip content in data-tippy-content attribute',
    trigger: 'mouseenter click',
    allowHTML: true,
    interactive: true,
    appendTo: 'parent',
    duration: 800,
  });

  const onTippyAnchorMouseEnter = ({target}) => {
    const tippyInstance = tippyInstances
        .find((tippyItem) => tippyItem.reference.dataset.tippyAnchorId === target.dataset.tippyAnchor);

    tippyInstance.show();

    target.addEventListener('mouseleave', onTippyAnchorMouseleave);
    target.addEventListener('blur', onTippyAnchorMouseleave);
  };

  const onTippyAnchorMouseleave = ({target}) => {
    const tippyInstance = tippyInstances
        .find((tippyItem) => tippyItem.reference.dataset.tippyAnchorId === target.dataset.tippyAnchor);

    tippyInstance.hide();

    target.removeEventListener('mouseleave', onTippyAnchorMouseleave);
    target.removeEventListener('blur', onTippyAnchorMouseleave);
  };

  if (!tippyAnchors.length) {
    return;
  }

  tippyAnchors.forEach((anchor) => {
    anchor.addEventListener('mouseenter', onTippyAnchorMouseEnter);
    anchor.addEventListener('focus', onTippyAnchorMouseEnter);
  });

  // lala[5].show();
};

export {initTooltip};
