const Gap = {
  MOBILE: 20,
  DESKTOP: 30,
};

const breakpoint413 = window.matchMedia('(max-width: 413px)');
const breakpoint767 = window.matchMedia('(max-width: 767px)');

const calculateSlidesGap = () => document.documentElement.clientWidth > 767 ? Gap.DESKTOP : Gap.MOBILE;

const initSliderPhotos = () => {
  const sliders = document.querySelectorAll('.slider-photos');

  if (!sliders.length) {
    return;
  }

  sliders.forEach((slider) => {
    const container = slider.querySelector('.slider-photos__container');
    const slides = container.querySelectorAll('.slider-photos__slide');
    const controls = slider.querySelector('.slider-photos__controls');
    const navBtns = controls.querySelector('.slider-photos__nav-btns');
    const prevBtn = navBtns.querySelector('.nav-btn--prev');
    const nextBtn = navBtns.querySelector('.nav-btn--next');
    const paginationElem = controls.querySelector('.slider-photos__pagination');
    const navigation = {
      prevEl: prevBtn,
      nextEl: nextBtn,
    };
    const pagination = {
      el: paginationElem,
      bulletClass: 'slider-photos__pagination-bullet',
      bulletActiveClass: 'slider-photos__pagination-bullet--active',
    };

    const checkIfNeedNavigation = () => {
      const gap = calculateSlidesGap();
      const slideWidth = slides[0].offsetWidth;
      const slidesLength = slides.length * (slideWidth + gap) - gap;

      return slidesLength > slider.offsetWidth + 1;
    };

    let swiper;
    let isNeedNavigation = checkIfNeedNavigation();

    const initSwiper = () => {
      // eslint-disable-next-line no-undef
      swiper = new Swiper(container, {
        slidesPerView: 'auto',
        loop: isNeedNavigation,
        loopedSlides: slides.length * 3,
        spaceBetween: 20,
        grabCursor: true,
        pagination,
        mousewheel: {
          forceToAxis: true,
        },
        breakpoints: {
          768: {
            centeredSlides: isNeedNavigation,
            spaceBetween: 30,
            pagination: false,
            navigation: isNeedNavigation ? navigation : false,
          },
        },
      });

      if (isNeedNavigation) {
        slider.classList.remove('slider-photos--low-content');
        navBtns.style.display = null;
      } else {
        slider.classList.add('slider-photos--low-content');
        navBtns.style.display = 'none';
      }
    };

    const reInitSwiper = () => {
      swiper.destroy(true, true);
      initSwiper();
    };

    const onWindowResize = () => {
      const checkResult = checkIfNeedNavigation();

      if (isNeedNavigation !== checkResult) {
        isNeedNavigation = checkResult;
        reInitSwiper();
      }
    };

    initSwiper();

    breakpoint413.addListener(reInitSwiper);
    breakpoint767.addListener(reInitSwiper);

    window.addEventListener('resize', onWindowResize);
  });
};

export {initSliderPhotos};
