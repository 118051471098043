const initSliderProductCard = () => {
  const sliders = document.querySelectorAll('.slider-product-card');

  if (!sliders.length) {
    return;
  }

  sliders.forEach((slider) => {
    const container = slider.querySelector('.slider-product-card__container');
    const slides = container.querySelectorAll('.slider-product-card__slide');
    const paginationElem = slider.querySelector('.slider-product-card__pagination');
    const pagination = {
      el: paginationElem,
      bulletClass: 'slider-product-card__pagination-bullet',
      bulletActiveClass: 'slider-product-card__pagination-bullet--active',
    };

    const slideZoneWidth = slider.offsetWidth / slides.length;
    let swiper;
    let isNotTouchDeviceValue;

    const isNotTouchDevice = () => !('ontouchstart' in window);

    const initSwiper = () => {
      isNotTouchDeviceValue = isNotTouchDevice();
      // eslint-disable-next-line no-undef
      swiper = new Swiper(container, {
        speed: isNotTouchDeviceValue ? 10 : 400,
        effect: isNotTouchDeviceValue ? 'fade' : 'slide',
        fadeEffect: isNotTouchDeviceValue
          ? {
            crossFade: true,
          }
          : false,
        pagination,
        mousewheel: {
          forceToAxis: true,
        },
      });

      if (isNotTouchDeviceValue) {
        paginationElem.style.display = 'none';
        swiper.allowTouchMove = false;
        slider.style.cursor = 'pointer';
      } else {
        paginationElem.style.display = null;
        swiper.allowTouchMove = true;
        slider.style.cursor = 'auto';
      }
    };

    const onMouseover = () => {
      if (isNotTouchDeviceValue) {
        let currentComputedSlide;

        const onMouseMove = (moveEvt) => {
          if (slides.length > 2) {
            moveEvt.preventDefault();

            currentComputedSlide = Math.floor((moveEvt.clientX - slider.getBoundingClientRect().left) / slideZoneWidth);

            if (currentComputedSlide !== swiper.activeIndex) {
              swiper.slideTo(currentComputedSlide);
            }
          }

          if (slides.length === 2) {
            swiper.slideTo(1);
          }
        };

        const onMouseout = (upEvt) => {
          upEvt.preventDefault();

          if (slides.length) {
            swiper.slideTo(0);
          }

          paginationElem.style.display = 'none';
          document.removeEventListener('mousemove', onMouseMove);
          window.removeEventListener('mouseout', onMouseout);
        };

        paginationElem.style.display = null;

        document.addEventListener('mousemove', onMouseMove);
        window.addEventListener('mouseout', onMouseout);
      }
    };

    const reInitSwiper = () => {
      if (swiper) {
        swiper.destroy(true, true);
      }

      initSwiper();
    };

    if (slides.length > 1) {
      initSwiper();

      slider.addEventListener('mouseover', onMouseover);

      window.addEventListener('resize', reInitSwiper);
    }
  });
};

export {initSliderProductCard};
