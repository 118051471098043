const depInputs = document.querySelectorAll('[data-id-master-select]');

const initDepInputs = () => {
  if (!depInputs.length) {
    return;
  }

  depInputs.forEach((depInput) => {
    const masterSelectWrapper = document.querySelector(`#${depInput.dataset.idMasterSelect}`);
    const targetValuesArr = depInput.dataset.masterSelectTargetValues;

    if (!masterSelectWrapper || !targetValuesArr) {
      return;
    }

    const masterSelect = masterSelectWrapper.querySelector('select');

    const targetValues = targetValuesArr.split(',');

    depInput.style.display = 'none';

    const onSelectChange = ({target}) => {
      setTimeout(() => {
        const isDepInputShown = targetValues.includes(target.value);

        if (isDepInputShown) {
          depInput.style.display = null;
        } else {
          depInput.style.display = 'none';
        }
      });
    };

    masterSelect.addEventListener('change', onSelectChange);
  });
};

export {initDepInputs};
